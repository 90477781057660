import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";

const ExecutiveCoachingBrigtonPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				sarah: file(relativePath: { eq: "testimonials/pier-2.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100)
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-2.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Executive Coach in Brighton";
	const description = "";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const sarah = data.sarah.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item:{ 
					url: `${siteUrl}`,
					id:`${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Executive Coaching",
				item: {
					url:`${siteUrl}/executive-coaching`,
					id:`${siteUrl}/executive-coaching`,
				},
			},

			{
				"@type": "ListItem",
				position: 3,
				name: "Brighton",
				item: {
					url:`${siteUrl}/executive-coaching/brighton`,
					id:`${siteUrl}/executive-coaching/brighton`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/executive-coaching/brighton`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Executive Coaching in Brighton",
						},
					],
				}}
			/>

			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Executive Coach Brighton"
				description1="Personal, Professional and Leadership Coaching for Executives in Brighton"
				description2=""
			/>

			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3 text-primary">
								Personal, Professional and Leadership Coaching for Executives in
								Brighton, East Sussex
							</h2>
							<p>Are you looking for executive coaching in Brighton?</p>
							<p>
								I’m Jason Cornes and as an executive and business coach, I’ve
								worked with numerous individuals here in Brighton over the last
								five years,, each with their own circumstances and very personal
								reasons for seeking the help of an executive coach. These
								clients come from a wide range of careers including, for
								example; a manager in an insurance company, the chairperson of a
								charity, a chartered accountant and an educational consultant.
								All working here in Brighton.
							</p>
							<p>So, if you find yourself:</p>
							<ul className="pl-4">
								<li>Feeling lonely in your leadership position</li>
								<li>Wishing to enhance your self-confidence</li>
								<li>Finding it difficult to decide or ask for what you want</li>
								<li>
									Allowing procrastination, a lack of focus or motivation to
									creep in
								</li>
								<li>
									Struggling to make a decision on a promotion, new job or
									business opportunity.
								</li>
								<li>Facing redundancy and would like to make a fresh start</li>
							</ul>
							<p>
								You will no doubt benefit from an experienced executive coach,
								someone who’s on your side, with a non-judgemental outside
								perspective and a listening ear. A coach who is willing you on
								to succeed, get you to where you want to go, and become the
								manager of your career and your life that you know you can be.
							</p>
							<StaticImage
								className="float-lg-right ml-lg-3 mb-3 mb-lg-3 mb-xl-1 service-page-body-image"
								src="../../images/jason-flip-chart.jpg"
							/>
							<p>
								Whether it’s starting a new business, advancing your corporate
								career, performing better in your current role or simply
								becoming a more confident, assertive leader - local executive
								coaching in Brighton can support you all the way. Helping you to
								achieve long-term change across both your professional and
								personal life.
							</p>
							<p>
								I love the diversity here in Brighton and Hove, both among its
								residents and its businesses. It has unique characters,
								tremendous strengths, cultural, sporting and economic
								attractions and it’s why so many flock to the city every year.
							</p>
							<p>
								And, while we can all boast about being the Green capital of
								Britain, a cultural hub or let’s face it one of the best cities
								to live and work in the whole country, we are all a bit like our
								city; while we flourish in some areas, presenting a glossy
								exterior to the world, we do struggle under the surface at
								times.
							</p>
							<p>
								To date, I’ve helped many clients in Brighton, from business
								owners to managers and executive leaders, do more than just
								gloss over the things that hold them back; I’ve coached them
								through these issues and out the other side.
							</p>
							<p>
								With remote/virtual or face-to-face executive coaching that
								covers:
							</p>
							<ul className="pl-4">
								<li>Professional development</li>
								<li>Leadership coaching</li>
								<li>Business coaching</li>
								<li>And career coaching</li>
							</ul>
							<p>
								I can help you become stronger, more resilient and much happier,
								wealthier, and confident in your personal and professional life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">A local case study from here in Brighton</h2>
							<p>
								Take Sarah who has a leadership role in a Brighton based
								charitable organisation. Sarah came to me one year ago, asking
								for help managing change in her career.
							</p>
							<p>
								She loved her current role and was passionate about the mission
								of the charity that she worked for, however she was moving to a
								different part of the country for family reasons. So Sarah had
								already agreed with her board of trustees that she would be
								leaving on an agreed date.
							</p>
							<p>The challenges for Sarah were multi-level:</p>
							<ul className="pl-4">
								<li>
									How to perform at her best in her current role for the time
									that she had left
								</li>
								<li>How to have a ‘good ending’</li>
								<li>
									Who within her team should she recommend for promotion into
									her current role
								</li>
								<li>How to manage the ‘hand over’</li>
							</ul>
							<p>
								And last but by no means least...what next? How could she
								combine her skills, training, experience, values, personality
								and interests to find a new career direction where she would
								feel in her element every day.
							</p>
							<p>
								You can read what Sarah wrote about our work together in ‘Local
								Testimonials’ below.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3 text-primary">Brighton based testimonials</h2>
						</Col>
					</Row>
					<HorizontalTestimonial
						name="Sarah Chief Executive Brighton"
						review="I felt stuck and not a little fearful and overwhelmed. With my
          impending move I knew I needed to grab hold of the situation and make
          changes, but I wasn't doing anything about it. Something was holding
          me back. I didn't know where to even begin. Jason helped me to see my
          situation clearly and explore the potential ways forward some of which
          I hadn’t even considered. We worked to better understand my
          personality, identify my values and what I was actually feeling and
          then together decided a plan for what I needed to do. Now, instead of
          just saying I must get on with it, I have done something about it with
          Jason holding me accountable each step of the way. Leaving my previous
          role was a better and happier experience than I could have hoped and
          Jason continues to help me to create my re-invented role as a
          self-employed consultant. I feel more capable and in charge of my life
          than ever before. Thank you so much Jason. I highly recommend him to
          help you manage changes in your career!"
						showLinkedIn="d-none"
						profilePic={sarah}
					/>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Ready to get to where you want to be?</h2>
							<p>
								Executive coaching can make a world of difference to your
								performance.
							</p>
							<p>
								I’ve worked with everyone from accountants to consultants and
								coaches to small business owners to university academics here in
								Brighton and across Sussex, and I’d love to discuss working with
								you.
							</p>
							<p>
								I live just down the road near Eastbourne in East Sussex and
								have many clients in Brighton. We can either meet face-to-face
								locally or remotely via Zoom or Skype, whatever suits you best.
								So while you’re here, why not book an introductory Zoom call
								with me?
							</p>
							<p>
								Let’s chat, get to know each other and see if there’s a way for
								us to work together.
							</p>
							<p>
								P.S. Like you, I've been there, life gets busy. There are a lot
								of distractions and people to please and you probably don't have
								a lot of free time and energy. But remember, nothing will
								improve or grow unless you are able to put your mind to. These
								business mentoring sessions can be at time that suits you, as
								and when you need them. So what have you got to lose?
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching in Brighton"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default ExecutiveCoachingBrigtonPage;
